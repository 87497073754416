<template>
  <app-collapse
    accordion
    type="margin"
    class="mt-1"
  >

    <app-collapse-item
      title="Filters"
      :is-visible="true"
    >
      <template #header>
        <div
          class="d-flex justify-content-between align-items-center text-primary h5 text-uppercase"
        >
          <feather-icon
            icon="SearchIcon"
            size="22"
            class="mr-1 d-none d-lg-block"
          />

          <span
            v-if="resultsTotal!==null"
          >
            {{ $tc("offers found", resultsTotal) }}
          </span>

          <span
            v-else
          >{{ $t('point.service_finder') }}</span>
        </div>

      </template>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <div class="d-md-none d-flex align-items-center justify-content-center">
                <div>
                  {{ getUserAddressFormattedShort }}
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon m-1"
                >
                  <feather-icon
                    icon="CrosshairIcon"
                    @click="updateUserCountryData()"
                  />
                </b-button>
              </div>
              <div class="d-none d-md-flex">
                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon
                      icon="MapPinIcon"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    :value="getUserAddressFormattedShort"
                    placeholder=""
                    class="search-point"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button variant="primary">
                      <feather-icon
                        icon="CrosshairIcon"
                        @click="updateUserCountryData()"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                v-if="filters.geolocationIsActive"
                class="py-1 pb-3 mb-sm-0 px-2 "
              >
                <vue-slider
                  id="serviceRadius"
                  v-model="locationRadius"
                  :direction="direction"
                  :min="1000"
                  :max="100000"
                  :interval="1000"
                  :tooltip="'always'"
                  :tooltip-formatter="`${serviceRadius} `+ this.$t('km')"
                  :tooltip-placement="'bottom'"
                  @drag-end="()=>setFilterRadius()"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group class="mb-2">
                <v-select
                  v-model="filters.languages"
                  :placeholder="$t('common.what_language_do_you_speak?')"
                  :options="localesList"
                  :reduce="text => text.label"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                >
                  <template #option="{ name }">
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <select-purposes :filters="filters" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>

</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BRow,
  BSpinner,
  VBToggle,
} from 'bootstrap-vue'
import store from '@/store'
import { locales, postStatuses, serviceOptions } from '@/mixins/options'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { useRemoteData } from '@/views/apps/user/service-providers-list/useProvidersList'
import { computed, ref, watch } from '@vue/composition-api'
import { useUserLocation } from '@/views/apps/user/useUser'
import VueSlider from 'vue-slider-component'
import SelectPurposes from '@/views/apps/service/offer/list/SelectPurposes.vue'
import vSelect from 'vue-select'

export default {
  components: {
    // AppCollapseItem,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    AppCollapse,
    AppCollapseItem,
    SelectPurposes,
    vSelect,
    // 3rd Party
    VueSlider,
    BButton,
    BOverlay,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [postStatuses],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    resultsTotal: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data() {
    return {
      options: this.filterOptions,
      submittingFetch: false,
      locationRadius: 25000,
      q: '',
    }
  },
  computed: {
    serviceRadius() {
      let km = 0
      const radius = this.locationRadius
      if (radius) {
        km = Math.floor(radius / 1000)
      }
      return km
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
  },
  watch: {
    // '$i18n.locale': function (newVal, oldVal) {
    //   this.fetchPurposes()
    // },
    q(newVal, oldVal) {
      this.setSearchQuery(newVal)
    },

  },
  // created() {
  //   this.fetchPurposes()
  // },
  methods: {
    setSearchQuery(val) {
      if (val.length > 3) {
        this.filters.q = this.q
      }
    },
    setFilterRadius() {
      this.filters.locationRadius = this.locationRadius
    },
    // fetchPurposes() {
    //   this.submittingFetch = true
    //   store.dispatch('point/getPointTypesAndPurposes')
    //     .then(response => {
    //       if (response.data) {
    //         this.options.pointPurposes = this.getTranslatedPointTypes(response.data)
    //       }
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       if (error.response.status === 404) {
    //         this.options.pointPurposes = undefined
    //       }
    //     })
    //     .finally(() => {
    //       this.submittingFetch = false
    //     })
    // },
    // getOptionPurposes(typesAndPurposes) {
    //   const result = []
    //   const optionsPurposes = this.filterOptions.pointPurposes.value || null
    //   const serviceRequestPurposes = typesAndPurposes.filter(item => item.slug === 'service_request')
    //   if (serviceRequestPurposes) {
    //     serviceRequestPurposes.forEach(type => {
    //       Object.values(type.point_purposes).forEach(purpose => {
    //         const qty = optionsPurposes !== null ? optionsPurposes.filter(p => p.id === purpose.id).map(p => p.qty).join() : null
    //         if (qty) {
    //           result.push({ text: this.$t(purpose.title), value: purpose.id, qty })
    //           // console.log(result)
    //         }
    //       })
    //     })
    //   }
    //   return result
    // },

  },
  setup(props) {
    const { getUserAddressFormattedShort, updateUserCountryData } = useUserLocation()

    const selectedPurposes = ref([])

    const selectedPurposesTitles = computed(() => selectedPurposes.value.map(obj => obj.title))

    // watch(selectedPurposes, val => {
    //   props.filters.pointPurposes = selectedPurposes.value.map(obj => obj.id)
    // })

    const { localesList } = locales()
    const {
      getTranslatedPointTypes, getTranslatedPointPurposes,
    } = serviceOptions
    const {
      fetchPointTypesAndPurposes, submittingFetch,
    } = useRemoteData()
    return {
      fetchPointTypesAndPurposes,
      submittingFetch,
      localesList,
      getTranslatedPointTypes,
      getTranslatedPointPurposes,
      selectedPurposes,
      selectedPurposesTitles,
      getUserAddressFormattedShort,
      updateUserCountryData,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped lang="scss">
.search-point.form-control{
  height: auto;
}
.input-group-prepend{
  border: 1px solid #d8d6de;
  border-radius: 0.357rem 0 0 0.357rem;
}
</style>
