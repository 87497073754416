<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar mt-2">
      <div
        class="sidebar-shop filter"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <div
          v-if="mqShallShowLeftSidebar"
          class="sidebar-header"
        >
          <feather-icon
            icon="XIcon"
            size="20"
            class="close-button"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </div>
        <b-row>
          <b-col
            cols="12"
          >
            <h6 class="filter-heading d-none d-lg-block">
              {{ $t('Filters') }}
            </h6>
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <!-- Overlay -->
        <b-overlay
          :show="submittingFetch"
          rounded
          opacity="0.6"
          spinner-medium
          spinner-variant="primary"
        >
          <b-card>
            <!-- Multi Range -->
            <!--          <div class="multi-range-price">-->
            <!--            <h6 class="filter-title mt-0">-->
            <!--              Multi Range-->
            <!--            </h6>-->
            <!--            <b-form-radio-group-->
            <!--              v-model="filters.priceRangeDefined"-->
            <!--              class="price-range-defined-radio-group"-->
            <!--              stacked-->
            <!--              :options="filterOptions.priceRangeDefined"-->
            <!--            />-->
            <!--          </div>-->

            <!-- Price Slider -->
            <!--          <div class="price-slider">-->
            <!--            <h6 class="filter-title">-->
            <!--              Price Range-->
            <!--            </h6>-->
            <!--            <vue-slider-->
            <!--              v-model="filters.priceRange"-->
            <!--              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
            <!--            />-->
            <!--          </div>-->

            <div
              v-if="options.pointPurposes.length"
            >
              <!-- Languages-->
              <div class="d-flex mb-1">
                <feather-icon
                  icon="GlobeIcon"
                  size="19"
                />
                <p class="mb-0 ml-50">
                  {{ $t('Language') }}
                </p>
              </div>
              <b-form-group class="mb-2">
                <v-select
                  v-model="filters.languages"
                  :options="localesList"
                  :reduce="text => text.label"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                >
                  <template #option="{ name }">
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
              <!-- Purposes -->
              <div class="d-flex my-1">
                <feather-icon
                  icon="ListIcon"
                  size="19"
                />
                <p class="mb-0 ml-50">
                  {{ $t('Purposes') }}
                </p>
              </div>
              <b-form-group class="my-1">

                <!--              <div-->
                <!--                v-for="option in options.pointPurposes"-->
                <!--                :key="option.id"-->
                <!--              >-->
                <!--                <span-->
                <!--                  v-b-toggle="'collapse' + option.id"-->
                <!--                  size="sm"-->
                <!--                >-->
                <!--                  {{ getTypeTitle(option.slug) }}-->
                <!--                </span>-->
                <!--                <b-collapse-->
                <!--                  :id="'collapse' + option.id"-->
                <!--                  class="mt-1"-->
                <!--                >-->

                <!--                  Hello!-->

                <!--                </b-collapse>-->
                <!--              </div>-->

                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <b-badge
                      v-for="(title, index) in selectedPurposesTitles"
                      :key="index"
                      variant="light-secondary"
                      pill
                    >
                      {{ title }}
                    </b-badge>
                  </div>
                  <div>
                    <b-button
                      v-if="selectedPurposes.length"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon"
                      @click="selectedPurposes=[]"
                    >
                      <feather-icon icon="XCircleIcon" />
                    </b-button>
                  </div>

                </div>
                <span
                  v-for="option in options.pointPurposes"
                  :key="option.id"
                >
                  <app-collapse>
                    <app-collapse-item
                      :title="option.title"
                    >
                      <b-form-checkbox
                        v-for="purpose in getTranslatedPointPurposes(option.point_purposes)"
                        :key="option.id+purpose.id"
                        v-model="selectedPurposes"
                        :value="purpose"
                        name="flavour-4a"
                        inline
                      >
                        <p>
                          {{ purpose.title }}
                        </p>
                      </b-form-checkbox>
                    </app-collapse-item>
                  </app-collapse>
                </span>

              </b-form-group>
            <!-- Authors -->
            <!--          <div class="author">-->
            <!--            <h6 class="filter-title">-->
            <!--              Authors-->
            <!--            </h6>-->
            <!--            <b-form-checkbox-->
            <!--              v-model="filters.userId"-->
            <!--              :value="options.userId"-->
            <!--              class="custom-control-primary"-->
            <!--            >-->
            <!--              Me-->
            <!--            </b-form-checkbox>-->
            <!--          </div>-->

            <!-- Ratings -->
            <!--          <div class="ratings">-->
            <!--            <h6 class="filter-title">-->
            <!--              Ratings-->
            <!--            </h6>-->
            <!--            <div-->
            <!--              v-for="rating in filterOptions.ratings"-->
            <!--              :key="rating.rating"-->
            <!--              class="ratings-list"-->
            <!--            >-->
            <!--              <b-link>-->
            <!--                <div class="d-flex">-->
            <!--                  <feather-icon-->
            <!--                    v-for="star in 5"-->
            <!--                    :key="star"-->
            <!--                    icon="StarIcon"-->
            <!--                    size="17"-->
            <!--                    :class="[{'fill-current': star <= rating.rating}, star <= rating.rating ? 'text-warning' : 'text-muted']"-->
            <!--                  />-->
            <!--                  <span class="ml-25">&amp; up</span>-->
            <!--                </div>-->
            <!--              </b-link>-->
            <!--              <div class="stars-received">-->
            <!--                <span>{{ rating.count }}</span>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->

            </div>
          </b-card>
        </b-overlay>
      </div>

      <div
        class="body-content-overlay"
        :class="{'show': mqShallShowLeftSidebar}"
        @click="$emit('update:mq-shall-show-left-sidebar', false)"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormRadioGroup,
  BLink,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BCollapse,
  BButton,
  VBToggle,
  BCardText,
  BOverlay,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BBadge,

} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import store from '@/store'
import { locales, postStatuses, serviceOptions } from '@/mixins/options'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { useRemoteData } from '@/views/apps/user/service-providers-list/useProvidersList'
import vSelect from 'vue-select'
import { computed, ref, watch } from '@vue/composition-api'
import { getCountry } from '@/utils/location/phoneCodeCountries'

export default {
  components: {
    // AppCollapseItem,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BLink,
    BCard,
    BFormCheckbox,
    BFormGroup,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    // 3rd Party
    VueSlider,
    BCollapse,
    BButton,
    BCardText,
    BOverlay,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [postStatuses],
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      options: this.filterOptions,
      submittingFetch: false,
    }
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      this.fetchPurposes()
    },

  },
  created() {
    this.fetchPurposes()
  },
  methods: {
    fetchPurposes() {
      this.submittingFetch = true
      store.dispatch('point/getPointTypesAndPurposes')
        .then(response => {
          if (response.data) {
            this.options.pointPurposes = this.getTranslatedPointTypes(response.data)
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 404) {
            this.options.pointPurposes = undefined
          }
        })
        .finally(() => {
          this.submittingFetch = false
        })
    },
    getOptionPurposes(typesAndPurposes) {
      const result = []
      const optionsPurposes = this.filterOptions.pointPurposes.value || null
      const serviceRequestPurposes = typesAndPurposes.filter(item => item.slug === 'service_request')
      if (serviceRequestPurposes) {
        serviceRequestPurposes.forEach(type => {
          Object.values(type.point_purposes).forEach(purpose => {
            const qty = optionsPurposes !== null ? optionsPurposes.filter(p => p.id === purpose.id).map(p => p.qty).join() : null
            if (qty) {
              result.push({ text: this.$t(purpose.title), value: purpose.id, qty })
              console.log(result)
            }
          })
        })
      }
      return result
    },

  },
  setup(props) {
    const selectedCountry = ref('')

    selectedCountry.value = localStorage.getItem('countryCode') ? getCountry(localStorage.getItem('countryCode')).name : ''

    const selectedPurposes = ref([])

    const selectedPurposesTitles = computed(() => selectedPurposes.value.map(obj => obj.title))

    watch(selectedPurposes, val => {
      props.filters.pointPurposes = selectedPurposes.value.map(obj => obj.id)
    })

    const { localesList } = locales()
    const {
      getTranslatedPointTypes, getTranslatedPointPurposes,
    } = serviceOptions
    const {
      fetchPointTypesAndPurposes, submittingFetch,
    } = useRemoteData()
    return {
      fetchPointTypesAndPurposes,
      submittingFetch,
      localesList,
      getTranslatedPointTypes,
      getTranslatedPointPurposes,
      selectedPurposes,
      selectedPurposesTitles,
      selectedCountry,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" >
// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';
@import '~@core/scss/base/components/include'; // Components includes
.checkbox-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
.sidebar-shop .sidebar-header .close-button{
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 1;
}
.sidebar-shop.filter.show {
  overflow: auto;
  background-color: $white;
}

.dark-layout .sidebar-shop.filter.show {
  background-color: $theme-dark-card-bg;
}

.custom-control.custom-control-inline.custom-checkbox{
  display: block;
}
</style>
